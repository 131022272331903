import Aos from "aos";
import React, { useEffect, useState } from "react";
import img from "../../assets/img/trucksside.jpg";
import { Link } from "react-router-dom";
import Button from "../Button";
import "./index.scss";
import Footer from "../footer/Footer";
import about1 from "../../assets/img/office.jpeg";
import about2 from "../../assets/img/20230817_113325.jpg";
import about3 from "../../assets/img/offices.jpeg";

function Values() {
  return (
    <div className="feat bg-gray pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="section-head col-sm-12">
            <h4>
              <span>Our Core</span> Values
            </h4>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="item">
              {" "}
              <span className="icon feature_box_col_one">
                <i className="fa fa-heart"></i>
              </span>
              <h6>Love</h6>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="item">
              {" "}
              <span className="icon feature_box_col_two">
                <i className="fa fa-anchor"></i>
              </span>
              <h6>Respect</h6>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="item">
              {" "}
              <span className="icon feature_box_col_three">
                <i className="fa fa-hourglass-half"></i>
              </span>
              <h6>Peace</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const About = () => {
  const [readMore, setReadMore] = useState(false); //set readMore to be false by default

  //collapse the content

  let readMore1 = `
    The Kgagamela Holdings offers comprehensive, value-added services to create an environment that minimizes risk and maximizes returns for our customers - allowing projects to be delivered on time and on budget, with no surprises. Our collaborative project approach sets the standard for communication with our customers to provide client-driven solutions and satisfaction.

    We pride ourselves on being very flexible and reacting quickly to customer needs as well as market demands. We are a service organization whose mission is to provide efficient solutions to our customers’ challenges.
`;
  useEffect(() => {
    Aos.init({ duration: 1500 });

    let el1 = document.getElementById("header-bar");

    el1.classList.remove("in-viewport");
    el1.classList.add("out-viewport");
    el1.classList.remove("animate__fadeInDown");

    el1.classList.add("animate__fadeInDown");
    // el4.style.display = "none";
  }, []);

  let scrool = () => {
    let url = window.location.href;
    url = url.split("#");
    url = url[0] + "#Contact";
    window.location.href = url;
  };

  const about = [
    {
      name: `Formed in 2004, Kgagamela Project CC t/a KGA LOGISTICS is one of the leading fully black owned and controlled diversified mining companies in South Africa with interests in ore mining, crushing and screening, side tipper transporting, and yellow plant.`,
      url: about1,
      title: "History",
    },
  ];

  return (
    <>
      <div className="m-auto p-0 w-100 vancacies py-4 mt-4">
        <h2 className="fw-bold text-uppercase my-4 pt-4 text-center">
          About Us
        </h2>
        <div className="container-fluid d-flex justify-content-around flex-wrap mx-0 my-1 px-1 about-all-container">
          {about.map((item, index) => (
            <div className="card m-0 p-0 my-2" key={index}>
              <img
                className="card-img-top"
                src={item.url}
                alt="Card image cap"
              />
              <div className="card-body bg-white">
                <h5 className="card-title second-theme">
                  <b>{item.title}</b>
                </h5>
                <p className="card-text">{item.name}</p>
              </div>
            </div>
          ))}
        </div>
        <Values />
      </div>
      <Footer />
    </>
  );
};

export default About;
