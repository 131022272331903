import Aos from "aos";
import React, { useEffect, useState } from "react";
import img from "../../assets/img/trucksside.jpg";
import { Link } from "react-router-dom";
import Button from "../Button";
import "./index.scss";
import Footer from "../footer/Footer";
import about1 from "../../assets/img/office.jpeg";
import about2 from "../../assets/img/20230817_113325.jpg";
import about3 from "../../assets/img/offices.jpeg";
import Services from "../Services/Services";

const Transport = () => {
  const [readMore, setReadMore] = useState(false); //set readMore to be false by default

  //collapse the content

  let readMore1 = `
    The Kgagamela Holdings offers comprehensive, value-added services to create an environment that minimizes risk and maximizes returns for our customers - allowing projects to be delivered on time and on budget, with no surprises. Our collaborative project approach sets the standard for communication with our customers to provide client-driven solutions and satisfaction.

    We pride ourselves on being very flexible and reacting quickly to customer needs as well as market demands. We are a service organization whose mission is to provide efficient solutions to our customers’ challenges.
`;
  useEffect(() => {
    Aos.init({ duration: 1500 });

    let el1 = document.getElementById("header-bar");

    el1.classList.remove("in-viewport");
    el1.classList.add("out-viewport");
    el1.classList.remove("animate__fadeInDown");

    el1.classList.add("animate__fadeInDown");
    // el4.style.display = "none";
  }, []);

  let scrool = () => {
    let url = window.location.href;
    url = url.split("#");
    url = url[0] + "#Contact";
    window.location.href = url;
  };

  const about = [
    {
      name: `Since 2004, our customers have come to know and trust the Kgagamela Holdings Standard for Construction. Our core values of integrity, reliability and innovation are integral to delivering a superior customer experience. We provide economical and efficient solution to our client’s challenges. We take ownership of
             the issues and understand our responsibility and commitment to our clients.`,
      url: about1,
      title: "Our Values",
    },
    {
      name: `Kgagamela Holdings is a full-service construction management and general 
            contracting firm that operate mainly in the Limpopo. We are a vertically integrated organization, composed of a variety of business units organized around industry sectors. The business units are very diverse and each unit specializes in its own core industry. This structure allows each division to operate and develop expertise based on the demands of its specific industry. It also provides stability 
            for the company as market forces vary within different market sectors.`,
      url: about2,
      title: "Core Services",
    },
    {
      name: `The Kgagamela Holdings offers comprehensive, value-added services to create an
             environment that minimizes risk and maximizes returns for our customers - allowing
              projects to be delivered on time and on budget, with no surprises. Our collaborative 
              project approach sets the standard for 
            communication with our customers to provide client-driven solutions and satisfaction.`,
      url: about3,
      title: "Why Choose Us",
    },
  ];

  return (
    <>
      <div id="Contact" className="py-4 mt-4 mx-0 w-100">
        <div className="container-fluid mt-4 mx-0 ">
          <h2 className="text-center fw-bold pt-4 text-uppercase mb-4 mx-0">
            Mining Division
          </h2>
          <div className=" py-3 px-0 mx-0 w-100">
            <div className="row align-items-center w-100">
              <div className="col-xl-6 mt-4">
                <div className="item" data-aos="fade-up">
                  <img
                    src={about2}
                    className="w-100 rounded mb-4 mb-xl-0"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="item shadow px-3 py-1" data-aos="fade-down">
                  <h4 style={{ textTransform: "uppercase", lineHeight: 2 }}>
                    we have venture capital investments in various small-scale
                    chrome and coal mining operations in the Limpopo and
                    Mpumalanga areas. Our mining model is that we provide seed
                    money into low risk, small-scale open cast mines that have a
                    long term growth potential and sustainability
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Transport;
