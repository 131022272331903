import React, { useEffect, useState } from "react";

import Header from "../Header";
import Body from "../Body";

export default function Home() {
  return (
    <>
      <Header />
      {/* <Body /> */}
    </>
  );
}
