import React, { Component, useEffect, useState } from "react";
import "./index.scss";

export default function Slider(props) {
  const [hovered, setHovered] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (e) => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };
  //translate(${position.x / 32}px, ${position.y / 12}px)
  const style = {
    transform: hovered ? ` scale(1.0)` : "translate(0px, 0px) scale(1.0)",
    transition: "transform 0.2s",
  };

  setTimeout(() => {
    //document.getElementsByClassName("title-slider")[0].style.display = "block";
  }, 1700);

  setTimeout(() => {
    document
      .getElementById("container-slider")
      .classList.remove("animate__fadeInLeft");
  }, 100);

  setTimeout(() => {
    //document.getElementsByClassName("img-home")[0].style.display = "block";
  }, 10);

  useEffect(() => {
    //document.getElementsByClassName("title-slider")[0].style.display = "none";
    document.getElementsByClassName("caption-p")[0].style.display = "none";
    document
      .getElementById("container-slider")
      .classList.add("animate__fadeInLeft");
    //document.getElementsByClassName("img-home")[0].style.display = "none";
    document.getElementsByClassName("img-logo ")[0].style.display = "none";

    //document.getElementById("line-slide").style.display = "none";
    setTimeout(() => {
      document.getElementsByClassName("caption-p")[0].style.display = "block";

      document.getElementsByClassName("img-logo ")[0].style.display = "block";
      // document.getElementById("line-slide").style.display = "block";
    }, 50);
  }, [props]);

  const styleimg = {
    backgroundImage: `url("${props.imgURL}")`,
  };

  return (
    <div
      id="home"
      className="p-0 m-0"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      <div
        className="container-slider animate__animated container-fluid m-0 p-0 "
        id="container-slider"
      >
        <div className="img-logo text-center animate__animated animate__zoomIn animate__slow py-4 px-4">
          <img src={props.logo} alt="logo" />
        </div>

        <div style={styleimg} className="container-fluid m-0 p-0 img-home">
          <img
            src={props.imgURL}
            className="d-hide"
            alt={props.alt}
            style={style}
          />
        </div>
        <div className="container-fluid m-0 p-0 bg-slider"></div>

        <div className="m-0 p-0 slider-body ">
          <div className="thing m-0 p-0 ">
            {/* <div className="title-slider text-center animate__animated animate__fadeInLeft py-2 px-3">
               <h1 className="caption ">{props.title} </h1> 
            </div> */}
            {/* <div className="line bg-color " id="line-slide"></div> */}

            <div className="description animate__animated text-light text-center">
              <p className="caption-p animate__animated  animate__fadeInUp animate__slow">
                {" "}
                {props.caption}{" "}
                <span onClick={props.onClick} id={props.moreid}>
                  {props.more}
                </span>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="light-left glow">
        <i className="text-white">{props.id}/5</i>
        {/* <i className="fa fa-truck-front text-white"></i> */}
      </div>
      <div className="light-right glow">
        <i className="fa fa-truck-front text-white"></i>{" "}
      </div>
      <div className="truck-fast">
        <i className="fa fa-truck-fast"></i>{" "}
      </div>
    </div>
  );
}
