import React, { useState } from "react";
import "./footer.scss";
import img from "../../assets/img/logo.jpg";

const Footer = () => {
  const [service, setService] = useState("hide");
  return (
    <div id="footer" className="py-3 position-relative">
      <div className="container position-relative">
        <ul className="list-unstyled d-flex justify-content-center">
          <li>
            <a href="facebook">
              <i className="fab fa-facebook-f fa-fw"></i>
            </a>
          </li>
          <li>
            <a href="twitter">
              <i className="fab fa-twitter fa-fw"></i>
            </a>
          </li>
          <li>
            <a href="whatsapp">
              <i className="fab fa-whatsapp fa-fw"></i>
            </a>
          </li>
          <li>
            <a href="tel:+27728284205">
              <i className="fas fa-phone fa-fw"></i>
            </a>
          </li>
        </ul>
        <hr className="w-90 mx-auto bg-white border border-light" />

        <ul className="list-unstyled d-flex justify-content-center footer-navbar">
          <li>
            <a href="/home">Home</a>
          </li>

          <li>
            <a href="/about">About Us</a>
          </li>

          <li className="position-relative top-0 service-parent">
            <a href="#services">Services</a>
            <ul
              className={
                "position-absolute rounded bg-white text-dark top-100 service-drop"
              }
            >
              <li>
                <a href="/transport" style={{ color: "black" }}>
                  Logistics
                </a>
              </li>
              <li>
                <a style={{ color: "black" }} href="/mining">
                  Mining
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a href="#Contact">Contact Us</a>
          </li>
        </ul>
        <hr className="w-90 mx-auto bg-white border border-light d-hide" />
        <ul className="text-white footer-contact justify-content-between">
          <li className="text-left my-1">
            <span className="bg-secondary rounded px-3 py-1 text-white ">
              {" "}
              Operation Contacts{" "}
            </span>
            <br />
            <i className="fa-solid fa-phone text-white mx-1"></i>
            <a href="tel:+27797168637" className="text-small">
              <small>+27 797 168 637</small>
            </a>{" "}
            /
            <a href="tel:+27786400907">
              <small>+27 786 400 907 </small>
            </a>
          </li>
          <li className="text-left my-1">
            <span className="bg-secondary rounded px-3 py-1 text-white ">
              {" "}
              Admin Contacts{" "}
            </span>
            <br />
            <i className="fa-solid fa-phone text-white mx-1"></i>
            <a href="tel:+27786459391" className="text-small">
              <small>+27 786 459 391</small>
            </a>
          </li>
        </ul>

        <div className="d-flex justify-content-center subscribe mt-4">
          <div>
            <i className="fas fa-envelope"></i>
          </div>
          <div>
            <input
              type="text"
              placeholder="Enter your email here..."
              name="Subscribeemail"
            />
          </div>
          <div>
            <a href="Subscribe">Subscribe</a>
          </div>
        </div>
        <p className="copyright text-center mt-3">
          <small>Copyright © 2023 All rights reserved -KGA Logistics </small>
        </p>
      </div>
      {/* <div className='position-absolute  footer-end'>
                <img src={img} alt="" />
            </div> */}
    </div>
  );
};

export default Footer;
