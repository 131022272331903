import React from "react";
import LinkButton from "../LinkButton";
import MenuBar from "../MenuBar";
import WhatsappIcon from "../../components/WhatsappIcon";
import ScrollIcon from "../../components/ScrollIcon";
import Button from "../Button";
import "./index.scss";
import { Outlet, Link } from "react-router-dom";

export default function Layout() {
  const Links = [
    { id: 1, open: false, text: "Home", link: "/home", menu: [] },
    { id: 3, open: false, text: "About Us", link: "/about", menu: [] },
    {
      id: 2,
      open: true,
      text: "Services",
      link: "#services",
      menu: [
        { id: 1, text: "Logistics", link: "/transport" },
        { id: 6, text: "Mining", link: "/mining" },
      ],
    },

    {
      id: 4,
      open: false,
      text: "Contact Us",
      link: "/contact",
      menu: [],
    },
  ];

  let icon = () => {
    return (
      <>
        <i className="fa fa-angle-down"></i>
      </>
    );
  };
  let icon1 = () => {
    return (
      <>
        <i className="fa fa-angle-up"></i>
      </>
    );
  };

  let scrool = () => {
    let url = window.location.href;
    url = url.split("#");
    url = url[0] + "#Contact";
    window.location.href = url;
  };
  return (
    <>
      <div
        className="nav container-fluid animate__animated  m-0 py-2 w-100 d-flex flex-row justify-content-between in-viewport"
        id="header-bar"
      >
        <div className="title px-4 py-2">
          {" "}
          <h3 className="text-center">KGA LOGISTICS</h3>{" "}
        </div>
        <div className="w-auto m-0 p-0 nav-bar-items d-flex flex-direction-row navbar-d px-4 py-2">
          {Links.map((Links) => (
            <LinkButton
              key={Links.id}
              expand={Links.open}
              text={Links.text}
              href={Links.link}
              menu={Links.menu}
            />
          ))}
        </div>

        <MenuBar />
      </div>
      {/* <ScrollIcon href="#footer" id="scroll1" icon={icon()} />
      <ScrollIcon href="#home" id="scroll2" icon={icon1()} /> */}

      <Outlet />
    </>
  );
}
