import logo from './logo.svg';
import './App.css';
import Header from './containers/Header';
import About from './containers/AllAboutUs';
import ServiceCard from './containers/ServiceCard';
import Layout from './containers/Layout'
import Home from './containers/Home'
import NoPage from './containers/NoPage'
import './common/styles/index.scss'
import Fail from './containers/Fail';
import Sucess from './containers/Sucess';
import Contacts from './containers/AllContacts';
import Transport from './containers/Transport';
import Mining from './containers/Mining';
import { Route, Routes, Outlet, BrowserRouter } from 'react-router-dom'

import Body from './containers/Body'

function App() {
  return (
    <BrowserRouter>
    <Layout/>
      <Routes>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contacts />} />
        <Route path="/transport" element={<Transport />} />
        <Route path="/mining" element={<Mining />} />
        <Route path="fail" element={<Fail />} />
        <Route path="success" element={<Sucess />} />
        <Route path="*" element={<NoPage />} />

      </Routes>
    
</BrowserRouter>

  );

}

export default App;
