import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";

import "./index.scss";
import Slider from "../Slider";

import img from "../../assets/img/TrucksNight.jpeg";

import img1 from "../../assets/img/close-up-construction-site-excavator-min-min.jpg";
import img2 from "../../assets/img/Kgaganela.png";
import img3 from "../../assets/img/TrucksSide.jpeg";
import img4 from "../../assets/img/20230817_113307.jpg";

import logo from "../../assets/img/kga_logo-removebg-preview.png";

import logo1 from "../../assets/img/kga_logo-removebg-preview.png";

//This is to add remove classes from animated elements so that they can animate again when the state changes

var index = 0;
export default function Header() {
  const getSlide = (list) => {
    let readMore = () => {
      document.getElementById(list.more[1]).style.display = "block";
    };
    return (
      <>
        <Slider
          title={list.title}
          caption={list.caption}
          imgURL={list.imgURL}
          alt={list.alt}
          logo={list.logo}
          more={list.more[0]}
          moreid={list.more[1]}
          onClick={readMore}
          id={list.id}
        />
      </>
    );
  };

  const [Sliders, setSliders] = useState([
    {
      title: "Logistics",
      caption:
        "KGA LOGISTICS offers comprehensive mining and related services.",
      imgURL: img1,
      id: 1,
      alt: "backgroud img",
      logo: logo1,
      more: ["", "readMoreMission"],
    },
    {
      title: "Mining ",
      caption: "KGA LOGISTICS is a leading logistics and mining company. ",
      imgURL: img2,
      id: 2,
      alt: "backgroud img",
      logo: logo,
      more: ["", "readmoreVisison"],
    },
    {
      title: "Mining ",
      caption:
        "One of the leading black owned side tipper transporters on the SADC Region.",
      imgURL: img,
      id: 3,
      alt: "backgroud img",
      logo: logo,
      more: ["", "readmoreVisison"],
    },
    {
      title: "Mining ",
      caption:
        "Second black owned   transporter to be RTMS certified in the Limpopo Province.   ",
      imgURL: img4,
      id: 4,
      alt: "backgroud img",
      logo: logo,
      more: ["", "readmoreVisison"],
    },
    {
      title: "Mining ",
      caption: "Established by Norman Mahabe in the year 2004  ",
      imgURL: img3,
      id: 5,
      alt: "backgroud img",
      logo: logo,
      more: ["", "readmoreVisison"],
    },
  ]);

  const [indexSlide, setIndexSlide] = useState(0);

  const [currentSlide, setSlide] = useState(null);

  const [intID, setID] = useState(null);

  const changeSlider = (index1) => {
    clearInterval(intID);

    if (indexSlide < Sliders.length - 1 && indexSlide >= 0) {
      let newVal = indexSlide + index1;
      if (newVal != -1) {
        setIndexSlide(newVal);
        changeDot(newVal);
      } else {
        setIndexSlide(Sliders.length - 1);
        changeDot(Sliders.length - 1);
      }

      console.log(indexSlide);
      setSlide(getSlide(Sliders[indexSlide]));
      changeDot(indexSlide);
    } else {
      setIndexSlide(0);
      changeDot(0);

      setSlide(getSlide(Sliders[indexSlide]));
    }
  };
  ///This is the function to control the dots color, to choose which dot to make active, we pass dot argument to index

  const changeDot = (dot) => {
    let el = document.getElementsByClassName("dot");

    for (let i = 0; i < el.length; i++) {
      el[i].classList.remove("active");
    }

    el[dot].classList.add("active");
  };

  useEffect(() => {
    setSliders(Sliders);
    setSlide(getSlide(Sliders[indexSlide]));

    const setint = setInterval(() => {
      if (window.scrollY <= 10) {
        if (index < Sliders.length - 1 && index >= 0) {
          let newVal = index + 1;
          if (index != -1) {
            console.log("Index is", newVal);
            //setIndexSlide(newVal);
            index = newVal;
          }

          setSlide(getSlide(Sliders[index]));
          changeDot(index);
        } else {
          index = 0;
          setSlide(getSlide(Sliders[index]));
          changeDot(index);
        }
      } else {
        if (index != 0) {
          index = 0;
          setSlide(getSlide(Sliders[0]));
          changeDot(index);
        }
      }
    }, 8000);

    setID(setint);

    return () => clearInterval(setint);
  }, []);

  return (
    <>
      <div className="header-container m-0 px-0 py-0">
        {/* <div className='banner-container h-100 m-0 p-0' id="banner-container">
                <div className='banner-header animate__animated animate__slow' id="banner-header"></div>
            </div> */}

        {currentSlide}

        {/* <WhatsappIcon phone="+27678844843" /> */}

        <div
          className="control-icons next-button px-3 py-3 m-0 text-white hover"
          onClick={() => changeSlider(1)}
        >
          <i className="fa fa-angle-right"></i>
        </div>
        <div
          className="control-icons prev-button px-3 py-3 m-0 text-white hover"
          onClick={() => changeSlider(-1)}
        >
          <i className="fa fa-angle-left"></i>
        </div>
        <div className="dots px-2 py-1 d-flex flex-row">
          <div
            className={"dot active"}
            onClick={() => {
              clearInterval(intID);
              changeDot(0);
              setSlide(getSlide(Sliders[0]));
            }}
          ></div>{" "}
          <div
            className="dot mx-2"
            onClick={() => {
              clearInterval(intID);
              changeDot(1);
              setSlide(getSlide(Sliders[1]));
            }}
          ></div>
          <div
            className="dot mx-1"
            onClick={() => {
              clearInterval(intID);
              changeDot(2);
              setSlide(getSlide(Sliders[2]));
            }}
          ></div>
          <div
            className="dot mx-1"
            onClick={() => {
              clearInterval(intID);
              changeDot(3);
              setSlide(getSlide(Sliders[3]));
            }}
          ></div>
          <div
            className="dot mx-2"
            onClick={() => {
              clearInterval(intID);
              changeDot(4);
              setSlide(getSlide(Sliders[4]));
            }}
          ></div>{" "}
        </div>
        <div className="button-slider m-0 p-0">
          <a
            href={"/about"}
            className="bg-light text-dark px-4 py-2 m-0 rounded-pill border shadow second-theme"
            icon="fa-arrow-right"
          >
            {" "}
            <b>Learn More</b>
          </a>
        </div>
      </div>
    </>
  );
}
