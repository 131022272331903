import "./index.scss";
import Component from "./Component";
import interview from "../../assets/img/Trucks.jpeg";
import speach from "../../assets/img/IMG-20220402-WA0043.jpg";
import { Link } from "react-router-dom";

const items = [
  {
    title: "Transportation",
    url: interview,
    link: "transport",
    menu: `Kgagamela Holdings is a freight company specialising in line haul logistics and employee
         transport as it is incorporated Kgwana Transport. Operating for more than seven years, the company has rapidly
         grown to become one of regional Limpopo’s most in-demand independent freight and employee businesses.`,
  },
  {
    title: "Mining",
    url: speach,
    link: "/mining",
    menu: `Our business is situated along the R37 Route, which is the main 
        road that connects Burgersfort and Polokwane, is on this area that there is vast deposit of minerals, 
        this comes with the shortage of black owned companies that can synergise with the mining houses. `,
  },
];

function Values() {
  return (
    <section id="Services" className="my-4 mx-0">
      <div className="container-fluid mx-0 my-4 py-0 clip-out">
        <h2 className="text-uppercase text-center fw-bold">Company Profile</h2>
        <div className="d-flex service-component-wrapper">
          {items.map((item, index) => (
            <Component item={item} key={index} />
          ))}
        </div>
      </div>

      <hr className="my-2 border primary" />
    </section>
  );
}

export default Values;
