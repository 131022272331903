import React, { useState } from "react";
import ReactDOM from "react-dom/client";

import "./index.scss"; //niazy eladawy

// function SideBar(props) {

//     return (

//     )
// }

export default function MenuBar(props) {
  const [openSlide, setOpen] = useState(false);
  //This is to control slide bar, the return value depend on useState
  // let sidebar = (openSlide, setSlide) => {
  //     if (openSlide) {
  //         return (

  //         )
  //     }
  //     else {
  //         return "";
  //     }

  // }
  //display the cross icon if slide is open and bars if slide is closed
  let icon = (openSlide) => {
    if (!openSlide) {
      return <i className="fa fa-bars"></i>;
    } else {
      return <i className="fa fa-close text-danger close-menuBar"></i>;
    }
  };

  //Return the main component
  if (!openSlide) {
    return (
      <>
        <div className="menuBar m-1" onClick={() => setOpen(!openSlide)}>
          {icon(openSlide)}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="menuBar m-1" onClick={() => setOpen(!openSlide)}>
          {icon(openSlide)}
        </div>
        <>
          <div className="slideBar  position-absolute animate__animated animate__fadeInLeft">
            <div className="ml-2 mt-2 slide-bar-title text-white animate__animated animate__fadeInDown animate__slow text-center">
              KGA Logistics
            </div>
            <hr className="mt-3 line-slide" />
            <ul className="sidebar-links list-unstyled  p-4 text-white fa-ul">
              <li
                onClick={() => setOpen(!openSlide)}
                className="animate__animated animate__fadeInDown"
                style={{ animationDelay: "0.1s" }}
              >
                <i className="fa-solid fa-house"></i> <a href="/home">Home</a>
              </li>
              <li
                onClick={() => setOpen(!openSlide)}
                className="animate__animated animate__fadeInDown"
                style={{ animationDelay: "0.3s" }}
              >
                <i className="fa-solid fa-book"></i>
                <a href="/about">About Us</a>
              </li>
              <li
                onClick={() => setOpen(!openSlide)}
                className="animate__animated animate__fadeInDown"
                style={{ animationDelay: "0.2s" }}
              >
                <i className="fa-solid fa-desktop"></i>
                <a href="/transport">Services</a>
              </li>

              <li
                onClick={() => setOpen(!openSlide)}
                className="animate__animated animate__fadeInDown"
                style={{ animationDelay: "0.4s" }}
              >
                <i className="fa-solid fa-car"></i>
                <a href="/transport">Logistics</a>
              </li>
              <li
                onClick={() => setOpen(!openSlide)}
                className="animate__animated animate__fadeInDown"
                style={{ animationDelay: "0.4s" }}
              >
                <i className="fa-solid fa-person-digging"></i>
                <a href="/mining">Mining</a>
              </li>
              <li
                onClick={() => setOpen(!openSlide)}
                className="animate__animated animate__fadeInDown"
                style={{ animationDelay: "0.5s" }}
              >
                <i className="fa-solid fa-phone"></i>
                <a href="/Contact">Contact US</a>
              </li>
            </ul>

            {/* <ul className='sidebar-footer text-white position-absolute bottom-0 pt-3 list-unstyled px-3 w-100 animate__animated animate__fadeInUp animate__slow'>
                            <hr />
                            <li><i className="fa-solid fa-phone"></i>Tel : +27 76 716 8637</li>
                            <li><i className="fa-solid fa-envelope"></i>jack@kgagamelaholdings.co.za</li>
                            <li><i className="fa-solid fa-location-dot"></i>R37 Road, Riba Cross,
                                Burgersfort ,
                                Limpopo</li>
                        </ul> */}
          </div>
        </>
      </>
    );
  }
}
