import React, { useState } from 'react';
import error from '../../assets/img/error.png';
import './index.scss';




export default function MenuBar(props) {
   
    return (
        <div className='m-auto p-0 w-90 hw-100'> 
        <div className="position-relative top-20 error_img text-center "> 
        <img className="m-auto" src={error} />
        </div>
        <div className='w-auto position-relative error_msg text-center hover m-0 p-3 border rounded text-danger display-6'>
       We couldn't find the page you're looking for.. <br/>Sorry 
            </div></div >
    )
}