import React from "react";

import "./index.scss";

export default function LinkButton(props) {
  console.log("This", props.menu[0]);
  let hover = (event) => {
    let links = document.getElementsByClassName("link-container");
    for (let i = 0; i < links.length; i++) {
      links.classList.remove("active");
    }
    event.targent.classList.add("active");
  };
  return (
    <>
      <div className="link-container m-1" onClick={hover}>
        <div className="link-name">
          <a href={props.href} className="p-2">
            {" "}
            {props.text}
            {props.expand ? <i className="fa fa-angle-down mx-2"></i> : ""}
          </a>
        </div>

        <div className="container-fluid m-0 p-0 bg-effect-hover"></div>
        <div className="dropdown bg-white shadow text-dark text-center px-0 py-0 m-0">
          {props.menu.map((item) => (
            <div className="my-2 mx-0 px-0 py-0">
              <a key={item.id} className="w-100 m-0 py-2 h4" href={item.link}>
                {item.text}
              </a>
              <br />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
