import React, { useState } from "react";
import ServiceSlider from "./ServiceSlider";
import img from "../../assets/img/plantHire.jpg"; //plantHire.jpg
import plant from "../../assets/img/plantHire.jpg"; //
import Transport from "../../assets/img/trucksside.jpg"; //
import planthire from "../../assets/img/20230817_113245.jpg"; //
import petrol from "../../assets/img/petrol.jpg";
import sand from "../../assets/img/sand.jpg";
import stone from "../../assets/img/ADT_Truck.jpeg";
import "./service.scss";

const Services = () => {
  const [items, setitems] = useState([
    {
      id: 5,
      title: "Material handling",
      img: Transport,
      description: ["Material handling"],
    },
    {
      id: 1,
      title: "Side tippers",
      img: Transport,
      description: ["34 ton side tippers "],
    },
    {
      id: 2,
      title: "Side tippers",
      img: Transport,
      description: ["50 ton side tippers "],
    },
    {
      id: 3,
      title: "Tippers",
      img: Transport,
      description: ["10 cube tippers "],
    },
    {
      id: 4,
      title: "Tippers",
      img: Transport,
      description: ["18 Cube tippers"],
    },
  ]);

  return (
    <div id="services" className="pb-4 mx-0 px-0 mb-4">
      <div className="container-fuild mx-0 px-2">
        <div className="services-body p-0 mt-3 text-center bg-white mx-0">
          <h2> Our Services</h2>
          <div className="bg-secondary text-white rounded px-1 py-1 my-2">
            <h3>1. Logistics Services</h3>
          </div>
          <ServiceSlider items={items} />
        </div>
      </div>
    </div>
  );
};

export default Services;
